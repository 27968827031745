import React, { useContext } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import {
  FilterChip,
  SearchInput,
  SortSelect,
  InviteOptions,
} from './components';
import { ExperienceFilter, ExperienceSort } from '@/webapi/use-experience-api';
import { MyExperiencesContext } from '@/features/dashboard/experiences/context';
import { isBrowser } from '@/utils/browser';

export function ExperienceStatusFilter() {
  const {
    filter,
    sort,
    searchText,
    setFilter,
    setSort,
    setSearchText,
    draft,
    abTests,
    oneHundredPercent,
    running,
    archived,
    paused,
    total,
  } = useContext(MyExperiencesContext);

  const onSelection = (value: ExperienceFilter) => {
    setFilter(value);
  };

  const onSearchTextChanged = (ev) => {
    const text = ev.target.value as string;
    setSearchText(text);
  };

  const onSortChanged = (sort: ExperienceSort) => {
    setSort(sort);
  };

  const debouncedSearch = useDebouncedCallback(onSearchTextChanged, 300);

  if (!isBrowser()) {
    return null;
  }

  return (
    <Wrapper>
      <FilterChip
        onClick={() => {
          total > 0 && onSelection(ExperienceFilter.UNKNOWN_STATUS);
        }}
        vselected={filter === ExperienceFilter.UNKNOWN_STATUS}
      >
        All ({running + draft + paused})
      </FilterChip>
      <FilterChip
        vdisabled={running === 0}
        onClick={() => {
          running > 0 && onSelection(ExperienceFilter.PUBLISHED);
        }}
        vselected={filter === ExperienceFilter.PUBLISHED}
      >
        Live ({running})
      </FilterChip>
      <FilterChip
        vdisabled={abTests === 0}
        onClick={() => {
          abTests > 0 && onSelection(ExperienceFilter.AB_TEST);
        }}
        vselected={filter === ExperienceFilter.AB_TEST}
      >
        A/B Test ({abTests})
      </FilterChip>
      <FilterChip
        vdisabled={oneHundredPercent === 0}
        onClick={() => {
          oneHundredPercent > 0 &&
            onSelection(ExperienceFilter.ONE_HUNDRED_PERCENT);
        }}
        vselected={filter === ExperienceFilter.ONE_HUNDRED_PERCENT}
      >
        100% ({oneHundredPercent})
      </FilterChip>
      <FilterChip
        vdisabled={draft === 0}
        onClick={() => {
          draft > 0 && onSelection(ExperienceFilter.DRAFT);
        }}
        vselected={filter === ExperienceFilter.DRAFT}
      >
        Draft ({draft})
      </FilterChip>
      <FilterChip
        vdisabled={paused === 0}
        onClick={() => {
          paused > 0 && onSelection(ExperienceFilter.PAUSED);
        }}
        vselected={filter === ExperienceFilter.PAUSED}
      >
        Paused ({paused})
      </FilterChip>
      <FilterChip
        vdisabled={archived === 0}
        onClick={() => {
          archived > 0 && onSelection(ExperienceFilter.ARCHIVED);
        }}
        vselected={filter === ExperienceFilter.ARCHIVED}
      >
        Archived ({archived})
      </FilterChip>
      <SearchInput defaultValue={searchText} grow onChange={debouncedSearch} />
      <SortSelect defaultValue={sort} onSelection={onSortChanged} />
      <InviteOptions />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  user-select: none;

  display: flex;
  flex-direction: row;

  && > * {
    margin-right: 0.8rem;
  }
`;
