import React, { useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Chip } from '@/features/details/shared';
import { DownIcon, formatDuration } from '@/features/details/date-pick/chip';
import { getRangeString } from '@/features/dashboard/shared/date-picker/shared';
import { DateRange } from '@/features/dashboard/reports/models';
import { DataPickerContext } from '@/features/dashboard/shared/date-picker/date-picker';

export const DatePickerChip = React.forwardRef(({ onClick }: any, ref) => {
  const { dateRange, startDate, endDate } = useContext(DataPickerContext);
  const message = getRangeString(dateRange);
  const marginRight = `1rem`;
  return (
    <DatesChip onClick={onClick} ref={ref}>
      <StaticImage
        style={{ marginRight }}
        src="./calendar.svg"
        alt="calendar"
        placeholder="none"
        loading="eager"
        formats={[`png`, `webp`]}
        height={10}
      />
      {message}
      {` `}
      {dateRange === DateRange.CUSTOM ? formatDuration(startDate, endDate) : ``}
      <DownIcon />
    </DatesChip>
  );
});

const DatesChip = styled(Chip)`
  && {
    color: #8897a4;
    font-size: 1.3rem;
  }
`;
