import * as React from 'react';
import { useContext, useEffect, useMemo } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import styled, { keyframes } from 'styled-components';
import { MyExperiences } from '@/features/dashboard/experiences';
import { PageLayout } from '@/components/page-layout';
import { Reports } from '@/features/dashboard/reports';
import { TabSelect } from '@/components/tab-select';
import { nav, openLinkInNewTab } from '@/utils/browser';
import { WelcomeQuiz } from '@/features/welcome-quiz';
import { StoreStatsKind } from '@/webapi/use-store-stats';
import { FeatureBit } from '@/webapi/use-auth-api';
import { AccountContext, useFeatureBit } from '@/features/account-context';
import { editorRoute } from '@/webapi/pages';
import {
  markNavToEditorOnFirstUseAsDone,
  shouldNavToEditorOnFirstUse,
} from '@/utils/should-nav-to-editor-on-first-use';
import { useShopifyApi } from '@/webapi/use-shopify-api';
import { Inspirations } from '@/features/dashboard/inspirations';
import { Audiences } from '@/features/dashboard/audiences';

type SelectedPage =
  | `LIVE`
  | `REPORTS`
  | `INSPIRATIONS`
  | `ACADEMY`
  | `AUDIENCES`;
const PageParam = withDefault(StringParam, `LIVE`);

export function Dashboard() {
  const [selected, setSelected] = useQueryParam(`pg`, PageParam);
  const {
    subscription,
    isAppEmbedRequired,
    setSdkInjectStatus,
    appEmbedStatus,
    showAppEmbedModalIfNeeded,
    isStoreGoalReached: isGoalReached,
  } = useContext(AccountContext);
  const showAppEmbedButtonRef = React.useRef(null);
  const isCustomAudiencesEnabled = useFeatureBit(
    FeatureBit.CUSTOM_AUDIENCES_TAB,
  );

  const isWelcomeQuizDone = isGoalReached(StoreStatsKind.IS_WELCOME_QUIZ_DONE);
  const isWelcomeQuizEnabled = useFeatureBit(
    FeatureBit.ENABLE_WELCOME_QUIZ,
    false,
  );
  const isV2Ftue = useFeatureBit(FeatureBit.ENABLE_V2_FTUE, false);

  const title = useMemo(() => {
    switch (selected) {
      case `LIVE`:
        return `My Experiences`;
      case `REPORTS`:
        return `Reports`;
      case `HELP-CENTER`:
        return `Help Center`;
      case `INSPIRATIONS`:
        return `Inspirations Library`;
      case `AUDIENCES`:
        return `Primary Audiences`;
      default:
        return `My Experiences`;
    }
  }, [selected]);

  const onPageSelected = (_: number, page: SelectedPage) => {
    if (page !== `ACADEMY`) {
      setSelected(page);
    }

    if (page === `ACADEMY`) {
      openLinkInNewTab(`https://help.visually.io`, `vsly_help`);
      setTimeout(() => {
        (document.querySelector(`.tab-${selected}`) as HTMLElement)?.click();
      }, 1000);
    }
  };

  useEffect(() => {
    // navigate to editor if user has not saved any experience
    if (!isWelcomeQuizDone) return;
    if (!isV2Ftue) return;
    if (
      !isGoalReached(StoreStatsKind.HAS_SAVED_ONE_EXPERIENCE) &&
      shouldNavToEditorOnFirstUse()
    ) {
      markNavToEditorOnFirstUseAsDone();
      nav(editorRoute());
    }
  }, [isV2Ftue, isWelcomeQuizDone]);
  const isAppEmbedRevertEnabled = useFeatureBit(FeatureBit.APP_EMBED_REVERT);

  let message = `Visually has not been installed properly. To preview or publish experiences, please enable Visually.io as an app embed.`;
  let enableText = `how to enable >`;
  if (isAppEmbedRevertEnabled) {
    enableText = `please install >`;
    message = `We detected that our sdk is not properly embedded. To ensure the experiences run successfully,`;
  }

  const { injectSdk, loading, response } = useShopifyApi(false);

  const onAppEmbedReinitClick = async () => {
    if (isAppEmbedRevertEnabled && !loading) {
      await injectSdk();
      setTimeout(() => {
        if (response.ok) {
          setSdkInjectStatus(true);
        }
      }, 500);
    } else {
      showAppEmbedModalIfNeeded();
    }
  };

  const shouldDisplayWarning = isAppEmbedRevertEnabled
    ? appEmbedStatus.isInit && !appEmbedStatus.legacy_sdk_installed
    : isAppEmbedRequired();

  const tabs = useMemo(() => {
    if (isCustomAudiencesEnabled) {
      return [`LIVE`, `AUDIENCES`, `REPORTS`, `INSPIRATIONS`, `ACADEMY`];
    }
    return [`LIVE`, `REPORTS`, `INSPIRATIONS`, `ACADEMY`];
  }, [isCustomAudiencesEnabled]);

  return (
    <>
      {shouldDisplayWarning && (
        <WarningAnnouncement>
          {message}
          <strong ref={showAppEmbedButtonRef} onClick={onAppEmbedReinitClick}>
            <u>{loading ? `...` : enableText}</u>
          </strong>
        </WarningAnnouncement>
      )}
      <PageLayout
        hideBackButton
        heading={title}
        headerControls={
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {subscription !== undefined && (
              <TabSelect
                tabHeight={3.2}
                tabWidth={10.2}
                containerPadding={0.5}
                defaultValue={selected}
                tabFont="Inter"
                tabs={tabs}
                onTabSelected={onPageSelected}
              />
            )}
          </>
        }
      >
        {isWelcomeQuizDone === false && isWelcomeQuizEnabled && <WelcomeQuiz />}
        {selected === `LIVE` && <MyExperiences />}
        {selected === `REPORTS` && <Reports />}
        {selected === `INSPIRATIONS` && <Inspirations />}
        {selected === `AUDIENCES` && <Audiences />}
      </PageLayout>
    </>
  );
}

const AnnouncementEffect = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
`;

const WarningAnnouncement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 0.8rem;

  background-color: #ffd491;
  color: #be3c00;
  font-family: Inter, serif;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;

  user-select: none;

  strong {
    cursor: pointer;
    margin-left: 1rem;
  }

  strong:hover {
    opacity: 0.8;
  }

  strong:active {
    opacity: 0.6;
  }

  animation: ${AnnouncementEffect} 500ms ease-in-out;
`;
