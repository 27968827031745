import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Experience, ExperienceStatus } from '@/webapi/use-experience-api';
import { formatEnum } from '@/utils/types';
import { VSpace } from '@/components/spacing';
import { Metric } from '@/components/metric';
import {
  getFormattedMetric,
  getMetric,
  getRelativeMetric,
  getStatusForScheduledExperien,
  nFormatter,
  parseScheduledTime,
} from '@/utils/experience-utils';
import { MetricKind } from '@/webapi/models';
import { DotLabel, getChipColor } from '@/components/dot-label';
import { Flexbox } from '@/components/flex';
import { formatToLocalDate, maybe } from '@/features/details/utils';
import { breakpoints } from '@/components/responsive';
import { ExperienceTileOptionsPopover } from '@/features/dashboard/experiences/options-popover';
import { shouldHideListSummary } from '@/utils/data_freshness';
import { CONTROL } from '@/features/details/shared';
import { DescriptionTooltip } from '@/components/tooltip';
import { conditional } from '@/utils/conditional';
import { formatter, isPercent } from '@/features/details/charts/chart';
import { nanGuard } from '@/features/details/summary/util';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

export function ExperienceTile({
  experience,
  onTileClick,
}: {
  experience: Experience;
  onTileClick: (Experience) => void;
}) {
  const onWrapperClick = useCallback(() => {
    onTileClick(experience);
  }, [experience]);

  const expStatus = getStatusForScheduledExperien(experience);
  const chipColor = getChipColor(expStatus);
  const chipText = formatEnum(expStatus).toLocaleLowerCase();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const showPopover = (ev) => {
    ev.stopPropagation();
    setIsPopoverOpen(true);
  };

  return (
    <Wrapper onClick={onWrapperClick}>
      <LeftPart>
        <StateChip bgColor={chipColor}>{chipText}</StateChip>
        <ExperienceDetails>
          <Title>
            <DescriptionTooltip
              id={experience.id}
              description={experience.description}
            />
            <span data-tip data-for={`desc${experience.id}`}>
              {experience.name}
            </span>
          </Title>

          <VSpace value={1} />
          <DotLabel dotColor={chipColor}>{getDateText(experience)}</DotLabel>
        </ExperienceDetails>
      </LeftPart>
      <Divider />
      <RightPart>
        <ExperienceStats experience={experience} />

        <Icon
          onClick={showPopover}
          style={{ cursor: `context-menu`, padding: `0 2rem 0 0` }}
        >
          <ExperienceTileOptionsPopover
            experience={experience}
            setIsVisible={setIsPopoverOpen}
            isVisible={isPopoverOpen}
          >
            <div>
              <StaticImage
                height={20}
                placeholder="none"
                loading="eager"
                src="../../../assets/three-dots.svg"
                alt="close"
              />
            </div>
          </ExperienceTileOptionsPopover>
        </Icon>
      </RightPart>
    </Wrapper>
  );
}

function getSessionTypeName(sessionType: string | undefined) {
  if (sessionType === `users`) {
    return `Users`;
  }
  return `Sessions`;
}

function ExperienceStats({ experience }: { experience: Experience }) {
  const primaryGoal = experience?.primaryGoal || experience?.goals?.[0];
  const goalMetric = getRelativeMetric(
    {
      ...experience,
      variants: experience.variants.filter((v) => v.publishedChance > 0),
    },
    primaryGoal,
  );

  const variation100 = maybe(() =>
    experience.variants.find((v) => v.publishedChance === 100),
  );
  if (variation100) {
    const value = getMetric(experience, primaryGoal, variation100.name);
    goalMetric.formatted = getFormattedMetric(experience, primaryGoal);
    if (
      [
        MetricKind.AVG_ORDER_VALUE,
        MetricKind.CHECKOUT_RATE,
        MetricKind.CONVERSION_RATE,
        MetricKind.PRODUCT_DETAILS_VIEWS_RATE,
        MetricKind.ADD_TO_CART_RATE,
        MetricKind.SIGNUPS_RATE,
        MetricKind.SUBSCRIPTION_RATE,
        MetricKind.CTR,
      ].includes(primaryGoal)
    ) {
      goalMetric.formatted = `${value.value > 0 ? `+` : ``}${(
        value.value * 100
      ).toFixed(2)}%`;
    }
    goalMetric.raw = getMetric(experience, primaryGoal).value;
    goalMetric.color = `#5b6971`;
  }
  const status = experience?.status;

  let metricName = formatEnum(primaryGoal);
  const idx = metricName.indexOf(`(`);
  metricName = metricName?.substring(0, idx > 0 ? idx : metricName.length);

  const formattedAbsoluteMetricValue = useMemo(() => {
    let metric = 0;
    if (isPercent(primaryGoal) && primaryGoal !== MetricKind.ADD_TO_CART_RATE) {
      metric =
        getMetric(experience, primaryGoal, variation100?.name).value * 100;
    } else {
      metric = getMetric(experience, primaryGoal, variation100?.name).value;
    }
    return nanGuard(formatter(primaryGoal)(metric));
  }, [experience, primaryGoal, variation100]);

  const expSessions = maybe(
    () =>
      nFormatter(
        experience.variants
          .map(
            (v) =>
              getMetric(experience, MetricKind.SESSIONS, v.name).value || 0,
          )
          .reduce((a, b) => a + b, 0),
        2,
      ),
    0,
  );
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {status === ExperienceStatus.PUBLISHED &&
      shouldHideListSummary(experience) ? (
        <ShouldHideWrapper withAllocate={shouldShowDetails(experience)}>
          {shouldShowDetails(experience) && (
            <Metric name="Allocation" value={formatAllocation(experience)} />
          )}
          <NoDataWrapper>
            <span>Waiting for data...</span>
            <span>We collect and process the data for you</span>
          </NoDataWrapper>
        </ShouldHideWrapper>
      ) : (
        <StatsWrapper
          style={{
            opacity: experience.status === ExperienceStatus.DRAFT ? `0.4` : `1`,
          }}
        >
          {shouldShowDetails(experience) ? (
            <Metric name="Allocation" value={formatAllocation(experience)} />
          ) : (
            <div />
          )}
          {shouldShowDetails(experience) ? (
            <Metric
              name={getSessionTypeName(experience?.sessionType)}
              value={expSessions}
            />
          ) : (
            <div />
          )}
          {conditional(
            <div />,

            shouldShowRelativeGoalMetric(experience),
            <Metric
              color={goalMetric.color}
              name={metricName}
              value={goalMetric.formatted}
            />,

            shouldShowAbsoluteGoalMetric(experience),
            <Metric
              color="#899f9c"
              name={metricName}
              value={formattedAbsoluteMetricValue}
            />,

            shouldShowRelativeGoalMetricBetweenVariants(experience),
            <Metric
              color={goalMetric.color}
              name={metricName}
              value={goalMetric.formatted}
              subtitle={`for ${goalMetric.best}`}
            />,
          )}
          <div />
        </StatsWrapper>
      )}
    </>
  );
}

function noVariantsWith100PercentAllocation(experience: Experience) {
  return maybe(
    () => !experience.variants.find((v) => v.publishedChance === 100),
  );
}

function controlHasAllocation(experience: Experience) {
  return maybe(
    () => !!experience.variants.find((v) => v.name === CONTROL && v.chance > 0),
  );
}

function shouldShowRelativeGoalMetric(experience: Experience): boolean {
  return (
    shouldShowDetails(experience) &&
    noVariantsWith100PercentAllocation(experience) &&
    controlHasAllocation(experience)
  );
}

function shouldShowAbsoluteGoalMetric(experience: Experience): boolean {
  return (
    shouldShowDetails(experience) &&
    !noVariantsWith100PercentAllocation(experience)
  );
}

function shouldShowRelativeGoalMetricBetweenVariants(
  experience: Experience,
): boolean {
  return (
    shouldShowDetails(experience) &&
    noVariantsWith100PercentAllocation(experience) &&
    experience.variants.length > 2
  );
}

function shouldShowDetails(experience: Experience): boolean {
  const isShowArchivedStats = useFeatureBit(
    FeatureBit.DASHBOARD_SHOW_ARCHIVED_STATS,
  );

  return (
    [ExperienceStatus.PUBLISHED].includes(experience?.status) ||
    (isShowArchivedStats &&
      ([ExperienceStatus.ARCHIVED].includes(experience?.status) ||
        [ExperienceStatus.PAUSED].includes(experience?.status)))
  );
}

function formatAllocation(experience: Experience): string {
  const runningVariants = experience.variants.filter(
    (v) => v.publishedChance > 0,
  );

  let control = maybe(
    () => runningVariants.find((v) => v.name === CONTROL).publishedChance,
    0,
  );

  if (control === 0 && runningVariants.length === 2) {
    // multi variant no control, 2 variants
    control = runningVariants[0].publishedChance;
  } else if (control === 0 && runningVariants.length > 2) {
    return `multi`;
  }

  const variant1 = 100 - control;

  if (control === 0) {
    return `100%`;
  }
  return `${variant1} / ${control}`;
}

const Wrapper = styled.div`
  background: white;
  border: 1px solid #f1f3f3;
  border-radius: 2.5rem;
  box-shadow: 0 1px 2px 0 rgb(0, 0, 0, 0.09), 0 2px 8px 0 rgb(7, 6, 6, 0.04);
  width: 87%;
  ${breakpoints.up(1500)} {
    width: 77%;
  }
  height: 11rem;
  padding: 1rem 4rem;

  font-family: 'Inter', serif;

  display: grid;
  grid-template-columns: 1fr 0.15rem 1fr;
  align-items: center;

  cursor: pointer;
  transition: background-color 0.5s ease-out;

  user-select: none;

  :hover {
    background-color: #fafafa;
  }
`;

const LeftPart = styled.div`
  display: grid;
  grid-template-columns: minmax(5rem, 7.4rem) 1fr;
  grid-column-gap: 2rem;
  align-items: center;
  text-align: left;
`;

const RightPart = styled.div`
  padding-left: 2rem;
  display: grid;
  grid-template-columns: 8fr 1fr;
`;

const Divider = styled.div`
  background: #f1f3f4;
  height: 50%;
`;

const StateChip = styled.span`
  text-transform: capitalize;
  width: auto;
  padding: 0.4rem 1rem;
  background-color: ${(p: any) => p.bgColor};
  color: white;
  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
`;

const ExperienceDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.span`
  font-size: 1.6rem;
  color: #333333;
  font-weight: 500;
`;

const StatsWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 5fr 5fr 5fr 1fr;
  grid-column-gap: 1rem;
`;

const ShouldHideWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props: { withAllocate: boolean }) =>
    props.withAllocate ? `1fr 3fr` : `1fr`};
  div:last-child {
    padding-left: ${(props: { withAllocate: boolean }) =>
      props.withAllocate ? `1rem` : `0rem`};
  }
`;

const NoDataWrapper = styled(Flexbox)`
  && {
    justify-content: center;
    font-family: 'JetBrains Mono', serif;
    color: #898989;
    width: 100%;
    height: 100%;
    span:first-child {
      font-size: 1.4rem;
      font-weight: 500;
      text-align: left;
    }

    span:last-child {
      font-size: 1.2rem;
      font-weight: 300;
      text-align: left;
    }
  }
`;

const Icon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

function getDateText(exp: Experience) {
  const status = getStatusForScheduledExperien(exp);

  if (status === ExperienceStatus.DRAFT)
    return `last edited: ${formatToLocalDate(exp?.updatedAt)}`;
  if (status === ExperienceStatus.ARCHIVED)
    return `end date: ${formatToLocalDate(exp?.endDate || exp?.archivedAt)}`;
  if (status === ExperienceStatus.SCHEDULED)
    return `scheduled ${parseScheduledTime(exp?.schedule)}`;
  return `start date: ${formatToLocalDate(
    exp?.metricsStartAt || exp?.publishedAt,
  )}`;
}
