import React, { useEffect, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import { DateRange, StoreSummary } from '@/features/dashboard/reports/models';
import { useReportsApi } from '@/features/dashboard/reports/api';
import { MetricKind } from '@/webapi/models';
import {
  DateRangeParam,
  EndDateParameter,
  StartDateParameter,
} from '@/features/dashboard/shared/date-picker/date-picker';

export interface ReportingDataContext {
  storeSummary: StoreSummary;
  setStoreSummary: (x: StoreSummary) => any;
  loading: boolean;
  initialized: boolean;
  nodata: boolean;
}

export const ReportingDataContext = React.createContext(
  {} as ReportingDataContext,
);

export function getQueryDate(name: string) {
  try {
    return new Date(new URL(window.location.href).searchParams.get(name));
  } catch {
    return new Date();
  }
}

export function newReportDataCtx() {
  const [dateRange] = useQueryParam(`rn`, DateRangeParam);
  const [startDate] = useQueryParam(`s`, StartDateParameter);
  const [endDate] = useQueryParam(`e`, EndDateParameter);

  const [initialized, setInitialized] = useState(false);
  const [nodata, setNoData] = useState(false);
  const [storeSummary, setStoreSummary] = useState<StoreSummary>();
  const { getStoreSummary, loading } = useReportsApi();

  useEffect(() => {
    (async () => {
      const startDate = getQueryDate(`s`);
      const endDate = getQueryDate(`e`);
      const resp = await getStoreSummary(
        dateRange as DateRange,
        startDate,
        endDate,
      );
      setNoData(sumRevenue(resp) === 0);
      if (resp?.metrics?.length > 0) {
        setStoreSummary(resp);
      }
      setInitialized(true);
    })();
  }, [dateRange, startDate, endDate]);
  return { initialized, nodata, storeSummary, setStoreSummary, loading };
}

function sumRevenue(resp: StoreSummary) {
  return (
    resp?.metrics?.reduce(
      (p, c) =>
        p +
        (c?.metrics?.find((m) => m?.name === MetricKind.REVENUE)?.value || 0),
      0,
    ) || 0
  );
}
