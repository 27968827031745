import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import useAsyncEffect from 'use-async-effect';
import { CardButton } from '@/components/card-button';
import {
  CatalogApp,
  useWidgetCatalogApi,
} from '@/webapi/use-widget-catalog-api';
import {
  CustomExperienceApp,
  FakeClickApp,
  PostPurchaseApp,
} from '@/features/editor/widgets/shared/apps-catalog/static-apps';
import { CatalogAppTile } from '@/features/editor/widgets/shared/apps-catalog/app-tile';
import { editorRoute, editorRouteWithWidget } from '@/webapi/pages';
import { nav } from '@/utils/browser';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

export function EmptyState({
  hasNoExperiences,
}: {
  hasNoExperiences: boolean;
}) {
  const isV2Ftue = useFeatureBit(FeatureBit.ENABLE_V2_FTUE, false);
  const { getAllApps } = useWidgetCatalogApi(false);
  const [catalog, setCatalog] = useState<CatalogApp[]>([]);

  useAsyncEffect(async () => {
    const resp = await getAllApps();
    if (!resp?.apps) return;
    let apps = resp?.apps?.filter((app) => !app.id.includes(`test`));

    apps.push(FakeClickApp);

    apps = apps.filter((app) => !app.id.includes(`code-block`));
    apps.push(PostPurchaseApp);

    apps.push(CustomExperienceApp);

    setCatalog(apps);
  }, []);

  const onClick = async (app: CatalogApp) => {
    if (app.id === CustomExperienceApp.id) {
      await nav(editorRoute());
    } else {
      await nav(editorRouteWithWidget(app));
    }
  };

  const onNewExperience = async () => {
    await nav(editorRoute());
  };

  return (
    <Wrapper>
      <CardButton
        padding="6rem"
        canBeSelected={isV2Ftue}
        hoverBackground="#ffffff"
      >
        {isV2Ftue && (
          <V2Wrapper>
            <V2Text>
              {hasNoExperiences ? (
                <>
                  There are no experiences currently running. You can start{` `}
                  <br />
                  by running a custom experience and easily personalize, <br />
                  optimize, and test every section
                </>
              ) : (
                <>
                  There are no results matching your criteria, You can refine
                  <br />
                  your search criteria <b>OR</b> try running a custom experience
                  <br /> and easily personalize, optimize, and test every
                  section
                </>
              )}
            </V2Text>

            <V2Button onClick={onNewExperience}>Create new experience</V2Button>
          </V2Wrapper>
        )}
        {!isV2Ftue && (
          <Layout>
            <Text>
              {hasNoExperiences ? (
                <>
                  There are no running experiences yet, what would <br />
                  you like to start with?
                </>
              ) : (
                <>
                  There are no results matching your criteria, what would <br />
                  you like to start with?
                </>
              )}
            </Text>
            <Grid>
              {catalog?.map((app) => (
                <CatalogAppTile key={app.id} app={app} onClick={onClick} />
              ))}
            </Grid>
          </Layout>
        )}
      </CardButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  grid-gap: 3rem;
  justify-items: center;
  justify-content: center;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 33rem 33rem;
  grid-gap: 3rem;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span`
  color: #8296a4;
  font-family: Inter, serif;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
`;

const V2Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content;
  justify-content: center;
  justify-items: center;
  grid-gap: 4rem;
  padding: 8rem 0;
`;

const V2Text = styled.span`
  color: black;
  font-family: Inter, serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 3.5rem;
`;

const V2Button = styled.button`
  color: black;
  font-family: Inter, serif;
  font-size: 1.2rem;
  border: 1px solid black;
  padding: 1rem 3rem;
  border-radius: 2rem;
  cursor: pointer;

  transition: background-color 0.2s linear, color 0.2s linear,
    opacity 0.2s linear;

  &:hover {
    background-color: black;
    color: white;
  }

  &:active {
    opacity: 0.5;
  }
`;
