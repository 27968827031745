import React, { MutableRefObject, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { IoIosArrowForward } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';
// @ts-ignore
import bulb from './bulb.png';
import { AudiencesContext } from '@/features/dashboard/audiences/context';
import { HSpace, VSpace } from '@/components/spacing';
import { Audience, AudienceType } from '@/features/dashboard/audiences/models';
import { MetricKind } from '@/webapi/models';
import { nFormatter } from '@/utils/experience-utils';
import { SmallCloseButton } from '@/components/close-button';
import { nav } from '@/utils/browser';
import { detailsRoute, editorRouteWithAudience } from '@/webapi/pages';

// @ts-ignore
import plusIcon from './plus.svg';
import { SelectorTooltipWrapper } from '@/features/editor/widgets/changelog/tile';

type Props = { fromRef: MutableRefObject<any> };

export function AudiencesList({ fromRef }: Props) {
  const { loading, audiencesList, setIsModalVisible, isModalVisible } =
    useContext(AudiencesContext);
  if (loading) {
    return null;
  }

  const audienceSize = audiencesList?.length < 26;
  return (
    <>
      <SelectorTooltipWrapper />
      {audienceSize && (
        <AddSegmentButton
          isModalVisible={isModalVisible}
          ref={fromRef}
          type="button"
          onClick={() => setIsModalVisible(true)}
        >
          <Plus>
            <img src={plusIcon} alt="plus" />
          </Plus>
          <PlusSubtitle>Create a new audience</PlusSubtitle>
        </AddSegmentButton>
      )}
      {audiencesList.map((a) => (
        <AudienceCard key={a.id} audience={a} />
      ))}
      <VSpace value={4} />
    </>
  );
}

function getMetric(audience: Audience, kind: MetricKind) {
  let value = audience?.metrics?.find((x) => x.name === kind)?.value || 0;
  if (kind.toString().toLowerCase().includes(`rate`)) {
    value *= 100;
  }
  return nFormatter(value, 2);
}

function AudienceMetricsInner({ audience }: { audience: Audience }) {
  const sessions = getMetric(audience, MetricKind.SESSIONS);
  const cr = getMetric(audience, MetricKind.CONVERSION_RATE);
  const aov = getMetric(audience, MetricKind.AVG_ORDER_VALUE);
  const revenue = getMetric(audience, MetricKind.REVENUE);
  return (
    <AudienceMetrics>
      <MetricCell>
        <MetricTitle>Sessions</MetricTitle>
        <VSpace />
        <MetricValue>{sessions}</MetricValue>
      </MetricCell>
      <MetricCell>
        <MetricTitle>Conversion Rate</MetricTitle>
        <VSpace />
        <MetricValue>{cr}%</MetricValue>
      </MetricCell>
      <MetricCell>
        <MetricTitle>Average Order Value</MetricTitle>
        <VSpace />
        <MetricValue>${aov}</MetricValue>
      </MetricCell>
      <MetricCell>
        <MetricTitle>Revenue</MetricTitle>
        <VSpace />
        <MetricValue>${revenue}</MetricValue>
      </MetricCell>
    </AudienceMetrics>
  );
}

const MetricCell = styled.div`
  padding: 1rem 2rem;
  border: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border-radius: 8px;
`;

function AudienceCard({ audience }: { audience: Audience }) {
  const { onDelete, onEdit } = useContext(AudiencesContext);
  const [isListView, setIsListView] = useState(false);
  const hasEditActions = audience?.type !== AudienceType.PRE_DEFINED;
  const shouldShowDescTooltip = useMemo(
    () => audience?.description?.length >= 50,
    [audience?.description],
  );
  if (isListView) {
    return (
      <AudienceCardWrapper isListView={isListView}>
        <Title>{audience?.name}</Title>
        <CloseBtnWrapper>
          <CloseListViewBtn type="button" onClick={() => setIsListView(false)}>
            <SmallCloseButton />
          </CloseListViewBtn>
        </CloseBtnWrapper>
        <VSpace value={1.5} />
        <AudSubTitle>Connected Experiences</AudSubTitle>
        <VSpace value={1.5} />
        <ConnectedExperience>
          {audience?.experiences?.map((e) => (
            <ExpName
              key={e.id}
              onClick={async () => nav(detailsRoute(e as any))}
            >
              <FlexRow>
                <GreenDot />
                <HSpace />
                {e.name}
              </FlexRow>
              <IoIosArrowForward size={20} />
            </ExpName>
          ))}
        </ConnectedExperience>
      </AudienceCardWrapper>
    );
  }

  const hasNoConnectedExperiences = !audience?.connectedExperiences;
  const onSeeMore = () =>
    nav(
      detailsRoute(
        {
          id: audience?.experienceId,
          isHidden: true,
        } as any,
        audience?.id?.length === 1 && audience.id,
      ),
    );

  const onCreateExperience = async () => {
    await nav(editorRouteWithAudience(audience.id));
  };

  return (
    <AudienceCardWrapper>
      <Title>{audience?.name}</Title>
      <VSpace value={1.5} />
      {hasEditActions ? (
        <>
          <Actions>
            <Edit onClick={() => onEdit(audience)} />
            <HSpace value={2} />
            {hasNoConnectedExperiences && (
              <Delete
                disabled={!hasNoConnectedExperiences}
                onClick={() => hasNoConnectedExperiences && onDelete(audience)}
              />
            )}
          </Actions>
          {audience?.description && (
            <>
              {shouldShowDescTooltip && (
                <Tooltip id={audience.id} text={audience?.description} />
              )}
              <AudSubTitle data-tip data-for={audience.id}>
                {audience?.description}
              </AudSubTitle>
            </>
          )}
        </>
      ) : (
        audience?.description && (
          <AudSubTitle>{audience?.description}</AudSubTitle>
        )
      )}
      {audience?.metrics && (
        <>
          <VSpace value={1.5} />
          <ConnectedExperiences
            onClick={() =>
              audience?.connectedExperiences && setIsListView(true)
            }
            disabled={hasNoConnectedExperiences}
            invisible={!audience?.metrics}
          >
            <span>
              {` `}
              {audience?.connectedExperiences
                ? audience?.connectedExperiences
                : `No`}
              {` `}
              Connected Experiences
            </span>
            <span className="plus" style={{ fontSize: `1.5rem` }}>
              +
            </span>
          </ConnectedExperiences>
        </>
      )}

      <VSpace value={1.5} />
      {audience?.metrics ? (
        <>
          <AudienceMetricsInner audience={audience} />
          <VSpace value={1.5} />
          <SeeMoreBtn onClick={onSeeMore}>See more</SeeMoreBtn>
          <VSpace value={1.5} />
          <CreateExperienceBtn onClick={onCreateExperience}>
            Create an experience for this audience
          </CreateExperienceBtn>
        </>
      ) : (
        <Row>
          <ImgBox>
            <img src={bulb} alt="bulb" />
          </ImgBox>
          <HSpace value={4} />
          <NoData>
            Data will be displayed <br />
            here once enough audience <br />
            data is collected
          </NoData>
        </Row>
      )}
      <VSpace />
    </AudienceCardWrapper>
  );
}

const CreateExperienceBtn = styled.button`
  padding: 1rem;
  border: 1px solid #111111;
  background: #111111;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 500;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  :active {
    opacity: 1;
    cursor: default;
  }
`;

const SeeMoreBtn = styled.button`
  padding: 1rem;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  color: #354452;
  font-weight: 500;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  :active {
    opacity: 1;
    cursor: default;
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;
const CloseBtnWrapper = styled.div`
  position: absolute;
  top: 5%;
  left: 87%;
  width: 50px;
`;

const GreenDot = styled.div`
  background-color: #2fe796;
  border-radius: 50%;
  width: 5px;
  height: 5px;
`;

const CloseListViewBtn = styled.button`
  top: -57px;
  left: 81%;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;
const ConnectedExperience = styled.div`
  padding: 0;
  width: 100%;
  max-height: 320px;
  overflow-y: scroll;
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const ConnectedExperiences = styled.div`
  background-color: #f5f5f5;
  padding: 1rem 2rem;
  border-radius: 8px;
  color: #63696c;
  display: flex;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
  opacity: ${(p) => (p.disabled ? 0.6 : 1)};
  visibility: ${(p) => (p.invisible ? `hidden` : `visible`)};
  pointer-events: ${(p) => (p.disabled ? `none` : `auto`)};
  &:hover {
    cursor: ${(p) => (p.disabled ? `default` : `pointer`)};
    opacity: ${(p) => (p.disabled ? 0.6 : 0.6)};
  }

  .plus {
    opacity: ${(p) => (p.disabled ? 0 : 1)};
  }
`;

const ImgBox = styled.div`
  width: 40px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const Row = styled.div`
  display: flex;
  height: 80%;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
`;
const NoData = styled.div`
  color: #a6afb8;
  font-family: 'Inter', serif;
  font-weight: 500;
  text-align: start;
  line-height: 27px;
  font-size: 1.3rem;
  display: flex;
`;
const PlusSubtitle = styled.span`
  user-select: none;
  margin-top: 2rem;
  color: #282a2c;
  font-size: 1.8rem;
  font-family: 'Inter', serif;
  font-weight: 500;
  text-align: center;
`;
const Plus = styled.p`
  color: #e1e4e7;
  user-select: none;
  font-family: 'Inter', serif;
  font-weight: bolder;
  font-size: 6rem;
  height: 70px;
  width: 70px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  background-color: white;
  border-radius: 50%;

  img {
    height: 30px;
    width: 30px;
  }
`;
const AddSegmentButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  padding: 2rem 4rem;
  background: #eff1f3;
  border-radius: 1rem;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.15),
    0 4px 11px 0 rgba(151, 210, 206, 0.22);

  flex-direction: column;

  cursor: pointer;
  font-family: 'Inter', serif;
  border: ${(p) => (p.isModalVisible ? 0 : 3)}px solid rgba(138, 141, 143, 0.5);

  :hover {
    opacity: 0.7;
  }

  :active {
    opacity: 1;
  }
`;

const MetricValue = styled.span`
  text-transform: uppercase;
  font-family: Inter, serif;
  font-weight: bold;
  font-size: 2.1rem;
  color: #354452;
  text-align: start;
`;
const MetricTitle = styled.span`
  font-family: 'Inter', serif;
  color: #a6afb8;
  font-weight: bold;
  font-size: 1rem;
  text-align: start;
`;
const AudienceMetrics = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
`;
const ExpName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3rem;
  letter-spacing: -0.57px;
  font-family: 'Inter', serif;
  color: #a6afb8;
  overflow: hidden;
  width: 100%;
  text-align: start;
  border: 1px solid #e6e6e6;
  padding: 2.5rem 1rem 2.5rem 1rem;
  border-radius: 8px;

  user-select: none;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  &:active {
    opacity: 1;
    cursor: default;
  }
`;

const AudSubTitle = styled.span`
  width: 100%;
  text-align: start;
  font-size: 1.3rem;
  letter-spacing: -0.57px;
  font-family: 'Inter', serif;
  color: #a6afb8;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
`;
const Actions = styled.div`
  position: absolute;
  top: 5%;
  left: 82%;
  width: 50px;
  display: flex;
  img {
    opacity: 0.5 !important;
  }
`;

function Delete({ onClick, disabled }: any) {
  return (
    <IconBtnWrapper disabled={disabled} onClick={onClick}>
      <StaticImage
        src="./delete.svg"
        alt="delete"
        placeholder="none"
        formats={[`png`]}
        loading="eager"
        quality={100}
        height={18}
      />
    </IconBtnWrapper>
  );
}

const IconBtnWrapper = styled.span`
  :hover {
    img {
      opacity: ${(p) => (p.disabled ? 0.5 : 1)} !important;
      cursor: pointer;
    }
  }
`;

function Edit({ onClick }: any) {
  return (
    <IconBtnWrapper onClick={onClick}>
      <StaticImage
        src="./edit.svg"
        alt="edit"
        placeholder="none"
        formats={[`png`]}
        loading="eager"
        quality={100}
        height={18}
      />
    </IconBtnWrapper>
  );
}

const Title = styled.p`
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  font-size: 1.8rem;
  font-family: Inter, serif;
  font-weight: 500;
  letter-spacing: -0.79px;
  margin: 0;
`;
const AudienceCardWrapper = styled.div`
  position: relative;
  width: 400px;
  height: 430px;
  padding: 2rem;
  background: white;
  border: ${(p) =>
    p.isListView ? `solid 2px rgb(215,218,221)` : `solid 1px #eaeef0`};
  border-radius: 1rem;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.15),
    0 4px 11px 0 rgba(151, 210, 206, 0.22);

  display: flex;
  flex-direction: column;

  font-family: 'Inter', serif;
`;

const Tooltip = ({ id, text }) => (
  <ReactTooltip
    id={id}
    place="bottom"
    backgroundColor="#fff"
    textColor="#000"
    className="selector-tooltip"
  >
    <span>{text}</span>
  </ReactTooltip>
);
